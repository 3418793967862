let Environment = {
  // api_url: "http://192.168.20.70:8080",
  // api_url: "https://wizardapi.quecko.com",
  // api_url: "http://ec2-54-191-120-95.us-west-2.compute.amazonaws.com:8080",
  // marketPlaceContract: "0x161975F0f31e935eD9f622A43cdcbF7b0FC216a3",
  StackContact: "0x953be9C1ADb9c651bBEC52E614c06EEe7FF9AA27",
  // launchPadContract: "0xFc1F0Be40C59aBa1124532f4ceDE63Ff5CB84f1F",
  // nftLaunchpad: '0x5D22b2400cF591a6A08D8EBa5D69f871D1DeD367'
  ClamingContract: "0xb0486420CeB01fdE5D0B874740DcA38940Aa432c",
  // nftLaunchpad: '0xd8F75Dd989Ab32420155bce4804e5af0cA41F895',
  Doptoken: "0x97A9a15168C22B3C137E6381037E1499C8ad0978",
  StackGraph:
    "https://api.studio.thegraph.com/query/75971/dopstakingmainnet/version/latest",
};

export default Environment;
